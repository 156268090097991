<template>
  <div class="news-glopu">
    <div class="news-info">
      <div class="news-info-left" v-if="!showDetails">
        <div class="home-intellectual-property">
          <div class="intellectual-tab">
            <div
              v-show="newsList.length !== 0"
              class="intellectual-item"
              v-for="(item, index) in newsList"
              :key="index + 'property'"
              @click="newsDatails(item)"
            >
              <div class="left">
                <div class="date">
                  {{ item.createdDate.substring(0, 10).split("-")[1]
                  }}<span>·</span
                  >{{ item.createdDate.substring(0, 10).split("-")[2] }}
                </div>
                <div class="years">
                  {{ item.createdDate.substring(0, 10).split("-")[0] }}
                </div>
              </div>
              <div class="right">
                <div class="title">{{ item.title }}</div>
                <div class="text" v-html="item.desp"></div>
              </div>
            </div>
            <div
              style="text-align: center; padding: 100px; font-size: 24px"
              v-show="newsList.length === 0"
            >
              暂无此类新闻！
            </div>
          </div>
          <div class="page" style="text-align: center; padding: 20px">
            <!-- v-show="total > 0" -->
            <a-pagination
              class="pagination"
              :pageSize="dataFrom.pageSize"
              @change="pageCurrentChangeHandle"
              v-model="dataFrom.pageNum"
              :total="total"
              :show-total="
                (total) => `每页${dataFrom.pageSize}条，共${total}条`
              "
            />
          </div>
        </div>
      </div>
      <div class="news-details" v-if="showDetails">
        <div class="title">{{ detailsData.title }}</div>
        <p class="date">{{ detailsData.createdDate }}</p>
        <div class="content" v-html="detailsData.content"></div>
      </div>
      <div class="news-info-right">
        <div class="mumenlist hepl">
          <div class="title">文章分类</div>
          <div
            class="item-column box"
            v-for="(item, index) in columnList"
            :key="index + 'columnList'"
          >
            <div
              style="width: 100px; padding: 10px 0; cursor: pointer"
              @click="getNewsTitle(item)"
            >
              {{ item.title }}
            </div>
            <div class="news-item">
              <div
                v-for="(items, indexs) in item.children"
                :key="indexs + 'title'"
                class="item"
                style="color: #999"
                @click="getNewsClss(items)"
              >
                {{ items.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="hepl">
          <div class="title">新闻推荐</div>
          <div class="tj-box">
            <div
              v-show="newsXwList.length !== 0"
              class="item"
              v-for="(item, index) in newsXwList"
              :key="index + 'newshepl'"
              style="cursor: pointer"
            >
              <span @click="newsDatails(item)">
                {{ item.title }}
              </span>
            </div>
            <div style="text-align: center" v-show="newsXwList.length === 0">
              暂无新闻推荐！
            </div>
          </div>
        </div>
        <div class="title-box">
          <div class="title-item">
            <div class="title">商标注册</div>
            <div class="item-info">
              <div
                class="item"
                v-for="(item, index) in $store.state.home.trademark.serviceList"
                :key="index + 'trademark'"
                @click="toTrademark(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="title-item">
            <div class="title">专利申请</div>
            <div class="item-info">
              <div
                class="item"
                v-for="(item, index) in $store.state.home.patentlist
                  .serviceList"
                :key="index + 'patentlist'"
                @click="toPatentlist(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="title-item">
            <div class="title">版权登记</div>
            <div class="item-info">
              <div
                class="item"
                v-for="(item, index) in $store.state.home.copyrightList
                  .serviceList"
                :key="index + 'copyrightList'"
                @click="toCopyrightList(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Floating
      :consultant="
        JSON.parse($store.state.user.web_data.default_consultant.value)
      "
    />
  </div>
</template>

<script>
import { news, newsColumnList, news_detail } from "../../api/index";
import Floating from "../../comon/Floating.vue";
export default {
  name: "News",
  components: { Floating },
  data() {
    return {
      total: 0,
      dataFrom: {
        pageSize: 10,
        pageNum: 1,
      },
      columnList: [],
      newsList: [],
      newsXwList: [],
      detailsData: {},
      showDetails: false,
    };
  },
  created() {
    if (
      this.$route.query.item &&
      this.$route.query.item !== "[object Object]"
    ) {
      this.newsDatails(this.$route.query.item);
      this.showDetails = true;
    }
    this.getXwNews();
    this.getNews();
  },
  methods: {
    pageCurrentChangeHandle(val) {
      this.dataFrom.pageNum = val;
      this.getNews();
    },
    toCopyrightList(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: "copyrightList",
        },
      });
    },
    toTrademark(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: "trademark",
        },
      });
    },
    toPatentlist(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: "patentlist",
        },
      });
    },
    async newsDatails(item) {
      const params = {
        id: item.id,
        webview: 1,
      };
      const res = await news_detail(params);
      if (res.code === 200) {
        this.detailsData = res.data;
        this.showDetails = true;
        this.$router.push("/news?details");
      }
    },
    async getNewsClss(item) {
      const params = {
        pageNum: 1,
        pageSize: 10,
        columnId: item.id,
      };
      const paramsXw = {
        pageNum: 1,
        pageSize: 10,
        columnId: item.id,
        isRec: 1,
      };
      const resXw = await news(paramsXw);
      if (resXw.code == 200) {
        this.newsXwList = resXw.data.list;
      }
      const res = await news(params);
      if (res.code === 200) {
        this.showDetails = false;
        this.total = res.data.total;
        this.newsList = res.data.list;
        this.$router.push("/news?details");
      }
    },
    async getXwNews() {
      const paramsXw = {
        pageNum: 1,
        pageSize: 10,
        isRec: 1,
      };
      const resXw = await news(paramsXw);
      if (resXw.code === 200) {
        this.newsXwList = resXw.data.list;
      }
      const resc = await newsColumnList();
      if (resc.code === 200) {
        this.columnList = resc.data;
      }
    },
    async getNewsTitle(item) {
      const params = {
        pageNum: 1,
        pageSize: 10,
        columnId: item.id,
      };
      const res = await news(params);
      if (res.code === 200) {
        this.total = res.data.total;
        this.newsList = res.data.list;
        this.showDetails = false;
        this.$router.push("/news?title");
      }
    },
    async getNews() {
      const params = {
        pageNum: this.dataFrom.pageNum,
        pageSize: 10,
      };
      const res = await news(params);
      if (res.code === 200) {
        this.total = res.data.total;
        this.newsList = res.data.list;
      }
    },
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: {
      handler(to) {
        if (to.fullPath === "/news") {
          this.getXwNews();
          this.showDetails = false;
          this.getNews();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../less/news.less";
</style>
